<template>
  <div class="login-container">
    <h2 class = 'h2-ele'> 카카오톡 연결 </h2>
    <button class="btn btn-default" @click="login">
      <img src="https://kr.object.ncloudstorage.com/candiy-bucket/assets/kakao/kakao_login_large_wide.png" width="300px">
    </button>
    <span v-if="error" style="color: #dc3545; font-size: 80%;">{{error}}</span>
  </div>
</template>

<script>
import queryString from 'querystring'
export default {
  name: 'kakao-login-button',
  data() {
    return {
      error: ''
    }
  },
  props: {
    // clientId: {
    //     type: String,
    //     require: true
    // },
    //
    // clientSecret: {
    //     type: String,
    //     require: true
    // },
    //
    // callbackUri: {
    //     type: String,
    //     require: true
    // },
    //
    // addFriend: {
    //     type: Boolean,
    //     require: false
    // },
    //
    // friendRequired: {
    //     type: Boolean,
    //     require: false
    // },
    //
    // friendErrorTest: {
    //     type: String,
    //     require: false,
    //     default: 'Add me as a friend.'
    // },
    //
    loginText: {
      type: String,
      require: false,
      // default: 'LINE LOGIN'
      default: '로그인'
    }
  },
  async created() {
    this.clientId = process.env.VUE_APP_KAKAO_CLIENT_ID
    this.clientSecret = process.env.VUE_APP_KAKAO_CLIENT_SECRET
    this.callbackUri = process.env.VUE_APP_KAKAO_CALLBACK_URL
  },
  methods: {
    login() {
      const url = 'https://kauth.kakao.com/oauth/authorize'
      let params = {
        response_type: 'code',
        client_id: this.clientId,
        redirect_uri: this.callbackUri,
        state: Math.random().toString(32).substring(2),
        scope: 'openid',
      }
      // if(this.addFriend) {
      //   params = Object.assign(params, {
      //     prompt: 'consent',
      //     bot_prompt: 'aggressive'
      //   })
      // }
      window.location.href = `${url}?${queryString.stringify(params)}`
    },
  }
}
</script>

<style>
.login-container {
  text-align: center;
  margin-bottom: 250px;
}
.h2-ele {
  margin-top: 30px;
  margin-bottom: 100px;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
.btn-social {
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-block {
  display: block;
  /* width: 100%; */
  width: 90%;
  margin: auto;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 5px;
  height:50px;
}
.btn-line {
  /* color: #fff; */
  /* background-color: #00b900; */
  /* border-color: rgba(0,0,0,0.2); */
  color: #fff;
  background-color: #00b900;
  border-color: #00b900;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-social>:first-child {
  height: 32px;
  width: 32px;
  line-height: 34px;
  font-size: 1.6em;
  text-align: center;
  /* border-right: 1px solid rgba(0,0,0,0.2); */
  /* border-right: 2px solid #00b900; */
  border: none
}
.fa-line {
  background: url(https://kr.object.ncloudstorage.com/candiy-bucket/assets/kakao/kakao_login_large_wide.png) 3px 4px no-repeat;
  display: inline-block;
  width: 32px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0,0,0,0.2);
  background-size: 26px 24px;
  /* margin: 5px 5px 5px 110px */
}
</style>